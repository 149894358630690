import { Link } from 'gatsby';
import { Box, Heading, CheckBox } from 'grommet';
import * as React from 'react';

import siteConfig from '../../site-config';

export default ({ themeSwitch, themeType }) => (
  <header>
    <Box
      fill="horizontal"
      margin="small"
      pad="small"
      direction="row"
      justify="between"
      align="center"
    >
      <Link
        style={{
          boxShadow: 'none',
          textDecoration: 'none'
        }}
        to="/"
      >
        <Heading color="brand" margin="small">
          {siteConfig.title}
        </Heading>
      </Link>
      <CheckBox toggle checked={themeType === 'dark'} onClick={themeSwitch} />
    </Box>
  </header>
);
