module.exports = {
  //
  // Change information to your own:
  //
  // Site Info:
  title: 'LAMANDA',
  description: 'Blog personnel', // unused
  siteUrl: 'https://blog-glamanda.glama.now.sh/',
  footerText: '**2018**', // You can use Markdown markup here

  // Blog Author Info:
  author: 'Guillaume LAMANDA',
  authorImage:
    'https://pbs.twimg.com/profile_images/1009340866958413824/Xawqn8AB_400x400.jpg',
  authorBio: `
    Un passionné qui essaie d'être passionant.  
    Blockchain Enthousiast   
    NodeJS + GraphQL + React = 🔥
    
    📍Brest
  `,
  social: {
    twitter: 'OlamsInc',
    github: 'guillaumeLamanda'
    // reddit: 'ganevru'
  },

  // Services:
  googleAnalyticsTrackingId: `ADD YOUR TRACKING ID HERE`,

  // Site Style:
  lightTheme: 'base', // grommet | dark | base | dxc | aruba
  // leave darkTheme blank if you don’t need a theme switch
  darkTheme: 'dark', // '' | grommet | dark | base | dxc | aruba
  font: "'Raleway', sans-serif", // Roboto
  fontHeadings: '', // Merriweather
  brandColor: '#f4511e' // #7D4CDB
};
