import { Box, Grommet } from 'grommet';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createGlobalStyle } from 'styled-components';

import dark from '../themes/dark';
import light from '../themes/light';

import SiteFooter from './SiteFooter';
import SiteHeader from './SiteHeader';
import useDarkMode from '../hooks/useDarkMode';

const GlobalStyle = createGlobalStyle`
  img {
    max-width: 100%;
  }
  body {
    margin: 0;
  }
  a:hover {
    opacity: 0.9;
  }
`;

const THEMES = {
  light,
  dark
};

export default ({ children }) => {
  const [darkMode, setDarkMode] = useDarkMode();
  const [theme, setTheme] = useState('light');

  if (darkMode && theme !== 'dark') setTheme('dark');

  return (
    <Grommet theme={THEMES[theme]}>
      <GlobalStyle />
      <Box direction="column" align="center">
        <Box width="xlarge">
          <SiteHeader
            themeSwitch={() => {
              setTheme(theme === 'dark' ? 'light' : 'dark');
              setDarkMode(!darkMode);
            }}
            themeType={theme}
          />
          <main>
            <Box direction="row-responsive">
              <Box basis="large" flex="grow" direction="row-responsive">
                {children}
              </Box>
            </Box>
          </main>
        </Box>
      </Box>
      <SiteFooter />
    </Grommet>
  );
};
