import siteConfig from '../../site-config';

// https://github.com/grommet/grommet/wiki/Grommet-v2-theming-documentation
// https://github.com/grommet/grommet/tree/NEXT/src/js/themes
export default {
  global: {
    colors: {
      brand: '#f4511e',
      border: {
        dark: '#ebebeb',
        light: '#9e9e9e'
      },
      background: '#eeeeee',
      placeholder: 'rgba(68, 68, 68, 0.5)',
      control: {
        dark: '#0BAEE1',
        light: '#F4511E'
      },
      'accent-1': '#e69073',
      'accent-2': '#805040',
      'accent-3': '#e6d4cf',
      'accent-4': '#bf5430',
      'neutral-1': '#7eda6d',
      'neutral-2': '#4a8040',
      'neutral-3': '#d2e6cf',
      'neutral-4': '#46bf30',
      'neutral-5': '#547ca8'
    },
    elevation: {
      none: 'none',
      xsmall: '0px 1px 2px rgba(68, 68, 68, 0.5)',
      small: '0px 2px 4px rgba(68, 68, 68, 0.5)',
      medium: '0px 3px 8px rgba(68, 68, 68, 0.5)',
      large: '0px 6px 12px rgba(68, 68, 68, 0.5)',
      xlarge: '0px 8px 16px rgba(68, 68, 68, 0.5)'
    },
    drop: {
      background: 'rgb(226, 226, 226)',
      shadow: '0px 3px 8px rgba(68, 68, 68, 0.5)',
      border: {
        radius: '2px'
      }
    },
    input: {
      border: {
        radius: '4px'
      }
    },
    font: {
      family: siteConfig.font,
      face:
        "/* latin-ext */\n@font-face {\n  font-family: 'Raleway';\n  font-style: normal;\n  font-weight: 400;\n  src: local('Raleway'), local('Raleway-Regular'), url(https://fonts.gstatic.com/s/raleway/v13/1Ptug8zYS_SKggPNyCMIT4ttDfCmxA.woff2) format('woff2');\n  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;\n}\n/* latin */\n@font-face {\n  font-family: 'Raleway';\n  font-style: normal;\n  font-weight: 400;\n  src: local('Raleway'), local('Raleway-Regular'), url(https://fonts.gstatic.com/s/raleway/v13/1Ptug8zYS_SKggPNyC0IT4ttDfA.woff2) format('woff2');\n  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;\n}\n"
    }
  },
  layer: {
    background: '#eeeeee',
    overlay: {
      background: 'rgba(68, 68, 68, 0.5)'
    },
    border: {
      radius: '4px'
    }
  },
  checkBox: {
    border: {
      color: {
        dark: '#ebebeb',
        light: '#444444'
      }
    },
    check: {
      radius: '4px'
    },
    toggle: {
      radius: '2px'
    }
  },
  anchor: {
    color: {
      dark: '#0BAEE1',
      light: '#F4511E'
    }
  },
  heading: {
    font: false
  },
  radioButton: {
    border: {
      color: {
        dark: '#ebebeb',
        light: '#444444'
      }
    }
  },
  button: {
    border: {
      radius: '4px'
    }
  }
};
